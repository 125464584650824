import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ArtistsList.scss';
import { API_PATH, getArtists } from '../api/api';
import { Artist } from '../api/artist';
import defaultCraftImg from '../assets/icon.png';
import { Loading, MajError } from './Loading';

function ArtistsList() {
    const [artists, setArtists] = useState<Array<Artist>>();
    const [error, setError] = useState<MajError>({code: undefined, string: undefined});
    const renderedArtistsList = artists?.map((artist) => {
        if (artist.craft_image_url) return (<li key={artist._id}><Link to={`/artists/${artist._id}`}><div className="artist-container"><img src={`${API_PATH}/artists/image/${artist.craft_image_url}`} alt="Bild" /><p>{artist.name}</p></div></Link></li>);
        else return (<li key={artist._id}><Link to={`/artists/${artist._id}`}><div className="artist-container"><img src={defaultCraftImg} alt="Bild" /><p>{artist.name}</p></div></Link></li>);
    });
    useEffect(() => {
        getArtists()
            .then((response) => {
                setArtists(response.data);
            })
            .catch((error) => {
                console.error(error)
                setError({code: 1, string: 'Felkod 1, kan inte ladda listan på konstnärer, maila felkod och kommentar till developer@kvasarnomad.net'})
            });
    }, []);
    return (
        <div className="ArtistsList">
            {artists
                ? <ul>{renderedArtistsList}</ul>
                : (<Loading error={error} />)
            }
        </div>
    );
}

export default ArtistsList;
