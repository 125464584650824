import ArtistsMap from '../components/ArtistsMap';

import '../page.scss';
import './EventPage.scss';

import posterThumb from '../assets/poster-thumb.png';

function EventPage() {
    return (
        <div className="EventPage">
            <div className="pageItem">
                <div className="pageItem-header">
                    <h2>Karta för Skördefesten 2024</h2>
                </div>
                <div className="pageItem-body">
                    <p>
                        Karta för KonstMajrundan under Skördefesten 2024 är på gång att fastställas.
                    </p>
                </div>
                {/* <ArtistsMap /> */}
            </div>
        </div>
    );
}

export default EventPage;
