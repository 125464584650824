import { Link } from 'react-router-dom'

import Sponsors from '../components/Sponsors';

import '../page.scss'
import './StartPage.scss';

import posterThumbImage from '../assets/poster.png';
import flowerImage from '../assets/blomma.png';

function StartPage() {
    return (
        <div className="StartPage">
            <div className="pageItem">
                <div className="pageItem-header">
                    <h2>Inför Skördefesten 2024</h2>
                </div>
                <div className="pageItem-body news-body">
                    <p>
                        Tack för alla besökare till våra ateljéer under kristihimmel&shy;färdshelgen 2024.<br></br>Nu förbereder vi inför Skördefesten 2024, info om öppna ateljéer kommer inom kort.
                    </p>
                    <p>
                        KonstMajrundans medlemar hittar du <Link to="/artists">här</Link>
                    </p>
                    <div><img className='flowerImage' src={flowerImage} alt='bild på blomma' /></div>
                </div>
            </div>
            <div className='pageItem'>
                <Sponsors />
            </div>
        </div>
    );
}

export default StartPage;
